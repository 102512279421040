import React from 'react';
import {useParams} from "react-router-dom";
import services from "../data/data";

const Details = () => {
    const {tid} = useParams();
    console.log(tid);


    const service = services.find(s => s.id === Number(tid))
    return (

        <div className="p-4 w-full lg:container mx-auto">
            <div className="pt-16">
                <div className=" py-8 mt-8">
                    <div id="profiledescription" className="text-color">
                        <h1 className="px-4 font-bold text-2xl text-left">{service.name}</h1>
                        <img id="about-pic" src={service.image} alt="about Morroco" className="w-full  shadow-xl rounded-t-lg "/>
                            {service.descriptions.map(s =>
                                <div className="p-4 text-left bg-white rounded-b-lg shadow-lg text-gray-600">{s}</div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;